<template>
    <div class="sellOutSetUp">
        <edit-btns class="btn-box" 
            :bnts="bnts"
            @lishiClick="selectMouldIsShow=true"  
            @mobanClick="selectMouldIsShow=true"
            @deleteClick="cancelDoSoldOut(tableData)"
            @weihuClick="goMould()"
            @goBackClick="goBack()"
            @notAutoCancelSellOutClick="goCancelSellOut()"/>
        <div class="content-box">
            <div class="left-box">
                <div class="tr">
                    <span class="lable-txt">营业日期：</span>
                    <el-date-picker class="from-date"
                    :clearable="false"
                    :editable="false"
                    v-model="businessHours"
                    type="date"
                    placeholder=""
                    :default-value="new Date()">
                    </el-date-picker>
                    <div class="inlineBox">
                        <span class="text-label">模板：</span>
                        <el-select v-model="tempValue" placeholder="所有" value-key="Tmplate_ID">
                            <el-option value="" label="所有"></el-option>
                            <el-option
                                v-for="item in limitTempList"
                                :key="item"
                                :label="item.Tmplate_Name"
                                :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="bnt-search" @click="loadData()">查询</div>
                </div>
                <div class="table-box grey-table" v-table-el-height="'tableEl'">
                    <el-table ref="tableEl" border :data="tableDataFilter"  style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" highlight-current-row>
                        <el-table-column prop="Eat_XFKINDNAME" label="类别" width="50" align="left"></el-table-column>
                        <el-table-column prop="Eat_XFNAME" label="菜品名称" min-width="50" align="left"></el-table-column> 
                        <el-table-column prop="Limited_Num" label="估清限售数量" min-width="40">
                            <template #default="scope">
                                <span class="number" v-if="scope.row.SoldOut_Type==2">{{scope.row.Limited_Num}}</span>
                                <span v-if="scope.row.SoldOut_Type==0">沽清</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="EAT_PHASENAME" label="限售时段" width="50"> 
                            <template #default="scope">
                                <span v-if="scope.row.SoldOut_Type==2">{{scope.row.EAT_PHASENAME}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Sold_Num" label="已售数量" width="50">
                            <template #default="scope">
                                <span class="number" v-if="scope.row.SoldOut_Type==2">{{scope.row.Sold_Num}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="操作" min-width="60" v-if="operationType==1 || operationType==2">
                            <template #default="scope">
                                <el-button type="text" size="small" @click="cancelDoSoldOut([scope.row])">取消</el-button>
                                <el-button v-if="scope.row.SoldOut_Type==2" @click="handleClick(scope.row)" type="text" size="small">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                
                <div class="search-box">
                    <key-board-input class="search"
                        type="text" v-model="foodSearch" placeholder="菜品模糊检索"
                        isKeyEnterClose isBlurClose
                        :keyOptions="{isKeyDownEnter:true}"
                    ></key-board-input>
                </div>
            </div>
            <div class="right-box">
                <!-- <div class="filter-nav">
                    <div class="li selected">按菜品沽清</div>
                    <div class="li">按原材料沽清</div>
                </div> -->
                <div class="category-nav food-main-box"  v-if="orderMap_Type==2 ||orderMap_Type==1" :style="foodMainStyle">
                    <categorys-page 
                        :data="foodMainCategory" 
                        :row="foodMainArg.row" 
                        :col="foodMainArg.col" 
                        :selectIndex="mainCategoryIndex"
                        :bottomH="setBottomH"
                        :pageIndex="foodMainPageIndex"
                        navFirstName="全部大类"
                        v-slot="{item}" @navClick="onFoodNav">
                        {{item.MainCategory_Name}}
                    </categorys-page>
                </div>
                <div class="category-nav food-sub-box"  v-if="orderMap_Type==3 ||orderMap_Type==1" :style="foodSubStyle">
                    <categorys-page  
                        :data="foodSubCategorys" 
                        :row="foodSubArg.row" 
                        :col="foodSubArg.col" 
                        :selectIndex="foodSubCategoryIndex"
                        :bottomH="setBottomH"
                        :pageIndex="foodSubPageIndex"
                        navFirstName="全部小类"
                        v-slot="{item}" @navClick="onFoodSubNav">
                        {{item.SubCategory_Name}}
                    </categorys-page>
                </div>

                <div class="menu" :style="menuStyle">
                    <!-- 菜单 -->
                    <layout-rc class="item-box clearfix" :row="arg.row" :col="arg.col" :marginW="2" :marginH="2" :isVarCss="true">
                        <div class="item-li " rc-item v-for="item in foodsPages" :key="item"  @click="onPlaceFoods(item)">
                            <div class="title">
                                {{item.Food_Name}}
                            </div>
                            <div class="bottom-box">
                                <span class="text-left" v-if="item.Is_Limited && item.Is_LimitedProject">剩: {{item.RemainNum}}</span>
                                <span class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</span>
                            </div>
                            <tag-sold-out v-if="item.Is_SoldOut"></tag-sold-out>
                            <div class="tag tag-grey" v-else-if="!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0">售罄</div>
                            <div class="tag tag-limited" v-else-if="item.Is_Limited">限量</div>
                        </div>
                    </layout-rc>
                </div>
                <div class="search-box">
                    <key-board-input class="search"
                        ref="searchBox"
                        type="text" v-model="varietySelect" placeholder="菜品模糊检索"
                        isKeyEnterClose isBlurClose
                        :focusLock="true"
                        :keyOptions="{isKeyDownEnter:true}"
                        @keydown.enter="onEnter()"
                    ></key-board-input>
                    <div class="bnt shuaxin-bnt" @click="RefreshData()"><i class="iconfont icon-a-shuaxin3x"></i>刷新</div>
                    <div class="float-right">
                        <span class="last-page iconfont icon-fangxiangquan-left" @click="pageBotton()"></span>
                        <span class="page-number">{{ posFoodsPage.pageIndex }}/{{ posFoodsPage.pageSize }}页</span>
                        <span class="next-page iconfont icon-fangxiangquan-right" @click="pageBotton(1)" ></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 沽清操作 -->
        <modal-load :isShow="selloutEditIshow">
            <sell-out-edit-model :data="selectPosFood" :isShow="selloutEditIshow" v-on:closeModel="selloutEditIshow=false" v-on:confirm="selloutEdit"></sell-out-edit-model>
        </modal-load>
        <!-- 选以往日期加载沽清 -->
        <modal-load :isShow="selectOldDateIshow">
            <select-old-date-model :isShow="selectOldDateIshow" v-on:closeModel="selectOldDateIshow=false" v-on:confirm="selectOldDate"></select-old-date-model>
        </modal-load>
        <!-- 按模板设置限量菜品份数 -->
        <modal-load :isShow="selectMouldIsShow">
            <select-mould-model ref="selectMould" :isShow="selectMouldIsShow" v-on:closeModel="selectMouldIsShow=false" v-on:confirm="batDoSoldOut" @removeSoldOut="removeData"></select-mould-model>
        </modal-load>
    </div>
</template>

<script>
import SelectOldDateModel from './selectOldDateModel/selectOldDateModel.vue';
import sellOutEditModel from './sellOutEditModel/sellOutEditModel.vue'
import selectMouldModel from './selectMouldModel/selectMouldModel.vue'
import {cacheData} from '/src/services'

/**全局初始化数据 */
let _data={
    /**点菜界面类别显示方式 1：大类+小类，2：大类，3：小类 */
    orderMap_Type:1,
    arg:{row:6,col:6,marginW:'10',marginH:'10'}
};

export default {
    components: { 
        sellOutEditModel, 
        SelectOldDateModel,
        selectMouldModel,
    },
    name:'sellOutSetUp',
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
             _data.orderMap_Type = globalSysSetting.OrderMap_Type;
            Object.assign(_data.arg,{row:globalSysSetting.OrderMap_X,col:globalSysSetting.OrderMap_Y});
        }
        return {
            menuStyle:{},
            //大类 样式
            foodMainStyle:{},
            foodMainArg:{row:2,col:8},
            //小类 样式
            foodSubStyle:{},
            foodSubArg:{row:2,col:8},
            /**营业日期 */
            businessHours:new Date(),
            //沽清数据 菜品模糊搜索
            foodSearch:"",
            /**操作类型 -1 没有营业日期 0 当前操作比营业日期小 1 当前操作时间为营业日期 2 当前操作日期比营业日期大 */
            operationType:1,
            /**点菜界面类别显示方式 1：大类+小类，2：大类，3：小类 */
            orderMap_Type:_data.orderMap_Type,
            /**大类导航 选择 */
            mainCategoryIndex:0,
            /**小类导航 选择 */
            foodSubCategoryIndex:0,
            /**菜品 大类数据 */
            foodMainCategory:[],
             //大类分页 当前页码 
            foodMainPageIndex:1,
            /**菜品 小类数据*/
            foodSubCategorys:[],
            //小类分页 当前页码 
            foodSubPageIndex:1,
            /**大类导航 分页 */
            foodNavPage:{
                pageIndex:0,
                pageSize:0
            },
            /**小类导航 分页 */
            foodSubNavPage:{
                pageIndex:0,
                pageSize:0
            },
            /**菜品展现数据 */
            posFoods:[],
             /**菜品页码 */
            posFoodsPage:{
                pageIndex:1,
                pageSize:1
            },
            /**选中的 菜品数据 */
            selectPosFood:undefined,
            /**沽清操作 弹层是否显示 */
            selloutEditIshow:false,
            /**选以往日期加载沽清 弹层是否显示 */
            selectOldDateIshow:false,
            /**按模板设置限量菜品份数 弹层是否显示 */
            selectMouldIsShow:false,
            /**设置不自动取消沽清 */
            cancelSellOutIsShow:false,
            /**数据 */
            tableData:[],
            /**数据 记载中 */
            tableLoading:true,
            arg:_data.arg,
            /**菜品检索 */
            varietySelect:'',
            //菜品数据 
            posFoodData:{},
            //模板数据
            limitTempList:[],
            //选择模板
            tempValue:null,
        }
    },
    computed:{
        /**编辑按钮 */
        bnts(){
            let data=["goBack","moban","weihu","notAutoCancelSellOut"];
            if(this.operationType==1 || this.operationType==2){
                data.push({key:"delete",text:"全部删除"})
                data.push("lishi");
            }
            return data;
        },
        /**菜品分页显示 */
        foodsPages(){
            let size=this.arg.row*this.arg.col;
            return this.posFoods.slice((this.posFoodsPage.pageIndex-1)*size,this.posFoodsPage.pageIndex*size);
        },
        getPosFoodData(){
            return this.posFoodData.data||[];
        },
        tableDataFilter(){
            let data=this.tableData;
            if(this.foodSearch){
                let MenuTxt=this.foodSearch.toLowerCase();
                data=data.filter(pos=>(pos.Eat_XFNAME||"").toLowerCase().indexOf(MenuTxt)>=0 
                                            ||(pos.Eat_XFCODE||"").toLowerCase().indexOf(MenuTxt)>=0 
                                            || (pos.PINYIN||"").toLowerCase().indexOf(MenuTxt)>=0 );
            }
            if(this.tempValue?.TemplateLimitFoods?.length>0){
                let foods=this.tempValue?.TemplateLimitFoods.map(it=>it.Food_ID);
                data=data.filter(pos=>foods.indexOf(pos.Eat_XFBMID)>=0);
            }
            return data;
        },
        /**判断弹层是否 有显示的 */
        isModelShow(){
            return this.selloutEditIshow || this.selectOldDateIshow || this.selectMouldIsShow;
        }
    },
    mounted(){
        this.$emit("navIndex",4);
       
        let userInfo= this.$auth.getUserInfo();
        if(userInfo){//营业日期
            this.businessHours=userInfo.Rpt_Date;
            this.operationType=1; 
        }else{
            this.operationType=-1; 
        }

        //this.loadData();
        this.loadTempData();
        this.$cacheData.DeskPosconfigure().then((d)=>{
            if(d){
                this.orderMap_Type =_data.orderMap_Type= d.OrderMap_Type;
                
                this.arg.row=_data.arg.row=d.OrderMap_X;
                this.arg.col=_data.arg.col=d.OrderMap_Y;

                d.DisplayingConfigs?.forEach((it)=>{
                    let style={};
                    if(it.ScreenPx>0){
                        style["height"]=it.ScreenPx+"%";
                    }
                    if(it.Button_Color){
                        style["--bgColor"]=it.Button_Color;
                    }
                    if(it.Font_Name){
                        style["--fontFamily"]=it.Font_Name;
                    }
                    if(it.Font_Size){
                        style["--fontSize"]=it.Font_Size+"px"
                    }
                    if(it.Font_Color){
                        style["--fontColor"]=it.Font_Color
                    }
                    let arg={row:it.IntRow,col:it.IntCol};
                    if(it.Config_Kind==11){//菜品大类显示类型
                        this.foodMainArg=arg;
                        this.foodMainStyle=style;
                    }else if(it.Config_Kind==12){//为菜品小类显示类型   
                        this.foodSubArg=arg;
                        this.foodSubStyle=style;
                    }else if(it.Config_Kind==13){//为菜品显示类型   
                        this.menuStyle=style;
                        //this.foodMainArg=arg; 
                        //this.foodMainStyle=style;
                    }else if(it.Config_Kind==14){//为功能键显示类型   
                        this.footerArg=arg;
                        this.footerStyle=style;
                    }
                })
            }
        }).catch(e=>{
            console.log("基本配置获取失败："+e.message);
        })
        this.$cacheData.PosFoods().then((d)=>{
            this.posFoodData=d;
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        

    },
    watch:{
        getPosFoodData(){
            this.foodMainCategory=this.getPosFoodData;
            let all=[];//全部大类
            this.foodMainCategory.forEach(category=> {
                if(category.FoodSubCategorys && category.FoodSubCategorys.length>0){
                    all=all.concat(category.FoodSubCategorys);
                }
            });
            all._all=[];//全部小类
            if(all.length>0){
                all.forEach(sub => {
                    if(sub.PosFoods && sub.PosFoods.length>0)
                    all._all=all._all.concat(sub.PosFoods)
                });
            }
            this.foodMainCategory._all=all; //小分类
            //this.posFoods=all[0]?.PosFoods;
            if(this.mainCategoryIndex>=this.foodMainCategory?.length){this.mainCategoryIndex=0;}
            if(this.mainCategoryIndex>0){
                this.foodSubCategorys=this.foodMainCategory[this.mainCategoryIndex-1].FoodSubCategorys;
            }else{
                this.foodSubCategorys=this.foodMainCategory._all;
            }
            if(this.foodSubCategoryIndex>=this.foodSubCategorys?.length){this.foodSubCategoryIndex=0;}
            if(this.foodSubCategoryIndex>0){
                this.posFoods=this.foodSubCategorys[this.foodSubCategoryIndex-1].PosFoods;
            }else{
                this.posFoods=this.foodSubCategorys._all;
            }
            //this.onFoodNav();
        },
        /**菜品搜索 */
        varietySelect(){
            if(this.varietySelect!=''){
                this.foodSubCategoryIndex=0;
                this.mainCategoryIndex=0;
                let all=[];
                let MenuTxt=this.varietySelect.toLowerCase();
                this.foodMainCategory._all._all.map(pos=>{
                    if((pos.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0){
                        all.push(pos);
                    }
                })
                this.posFoods =all;
            }else{
                 this.posFoods =this.foodMainCategory._all._all;
            }
        },
        businessHours(newValue){
            let userInfo= this.$auth.getUserInfo();
            if(userInfo && userInfo.Rpt_Date){//营业日期
                let date=new Date(new Date(newValue).Format('yyyy-MM-dd'));
                let Rpt_Date=new Date(userInfo.Rpt_Date);
                if(Rpt_Date>date){//当前操作比营业日期小
                    this.operationType=0;
                }else if(Rpt_Date<date){//当前操作日期比营业日期大
                    this.operationType=2;
                }else{
                    this.operationType=1; 
                }
            }else {
                this.operationType=-1; 
            }
            this.loadData();
        },
        tempValue(){
            this.loadData();
        },
        posFoods(){
            let size=this.arg.row*this.arg.col;
            this.posFoodsPage.pageIndex=1;
            this.posFoodsPage.pageSize=Math.ceil(this.posFoods.length/size);
        },
        /**没有弹层显示时 */
        isModelShow(newVal){
            if(!newVal){
                console.log(newVal)
                this.$refs.searchBox.focus();
                //判断键盘 未标记前是否是显示的
                if(this.$refs.searchBox?.oldIsShow){
                    this.$refs.searchBox?.showKeyBoard();//显示键盘
                }
            }else{
                if(!this.$refs.searchBox?.oldIsShow && this.$keyBoard.isShow && this.$keyBoard.inputObj==this.$refs.searchBox?.$refs?.input?.$el){
                    this.$refs.searchBox.oldIsShow=true;
                }else{
                    this.$refs.searchBox.oldIsShow=false;
                }
            }
        }
    },
    methods:{
        //返回 或 返回坐台
        goBack(){
            let SysProjects=this.$cacheData.SysProjects.get();

            if(SysProjects?.some(it=>it.Project_Key=="Order" && it.Visible)){
                this.$router.push('diningTable');
            }else{
                this.$router.push('setup');
            }
        },
        setBottomH(){
            let top=this.$refs.searchBox?.getBoundingClientRect().top;
            if(top>0){
                return window.innerHeight-top;
            }
            return 0;
        },
        /**数据分页 */
        pageBotton(type){
            if(type==1){
                if(this. posFoodsPage.pageIndex<this.posFoodsPage.pageSize){
                    this.posFoodsPage.pageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.posFoodsPage.pageIndex==1){
                    this.$message.warning('已到第一页');
                }
                this.posFoodsPage.pageIndex<=1?1:this.posFoodsPage.pageIndex--;
            }
        },
        //加载模板数据
        loadTempData(){
            this.$httpAES.post("Bestech.CloudPos.GetLimitTemplate", {}).then((d)=>{
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.limitTempList=d.ResponseBody;
                }
            }).catch((e)=>{
                this.$alert("限量菜品设置模板数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**加载沽清数据 */
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.tableData=[];
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.GetSoldOutInfo",{
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                Operator_Name:userInfo?.Login_Name,
                User_ID:userInfo?.User_ID
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableData=d.ResponseBody.doSoldOutList||[];
                }else{
                    this.tableData=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableData=[];
                loading.close();
                this.$message.error('加载沽清数据失败：'+e);
                console.log('加载沽清数据失败：'+e);
            })
        },
        /**点击大类*/
        onFoodNav(item,index){
            this.varietySelect='';
            if(item){
                if(!item.FoodSubCategorys._all){
                    item.FoodSubCategorys._all=[];//全部菜品
                    item.FoodSubCategorys.forEach(sub => {
                        if(sub.PosFoods && sub.PosFoods.length>0)
                        item.FoodSubCategorys._all=item.FoodSubCategorys._all.concat(sub.PosFoods)
                    });
                }
                this.foodSubCategorys=item.FoodSubCategorys;
                this.mainCategoryIndex=index;
                this.onFoodSubNav();
            }else if(this.foodMainCategory && this.foodMainCategory._all){//全部
                this.foodSubCategorys=this.foodMainCategory._all;
                this.mainCategoryIndex=0;
                this.onFoodSubNav();
            }
        },
        /**点击分类 */
        onFoodSubNav(item,index){
            if(item){
                this.posFoods=item.PosFoods;
                this.foodSubCategoryIndex=index;
            }else if(this.foodSubCategorys && this.foodSubCategorys._all){//全部
                this.posFoods=this.foodSubCategorys._all;
                this.foodSubCategoryIndex=0
            }
            this.varietySelect="";
        },
        /**点击 菜品  */
        onPlaceFoods(item){
            if((this.operationType==1 && !item.Is_SoldOut) || (this.operationType==2 && item.Is_Limited)){
                let SubCategory_Name= this.foodMainCategory._all?.find(t=>t.PosFoods?.findIndex(sub=>sub.Food_ID==item.Food_ID)>=0)?.SubCategory_Name;
                this.selectPosFood={
                    Eat_XFKINDNAME:SubCategory_Name,//类别名称
                    Eat_XFBMID:item.Food_ID,//菜品ID
                    Eat_XFNAME:item.Food_Name,//菜品名称
                    Is_Limited:item.Is_Limited,//是否是限量销售
                    Eat_XFCODE:item.Food_Code,
                    PINYIN:item.Food_PinYin,
                };
                this.selloutEditIshow=true;
            }
        },
        /**移除指定沽清数据 */
        removeData(SoldOut_ID){
            let index=this.tableData?.findIndex(t=>t.SoldOut_ID==SoldOut_ID);
            if(index>=0){
                this.tableData.splice(index,1);
            }
        },
        /**新增 或修改 沽清数据 */
        setData(row,SoldOut_ID){
            if(row.SoldOut_ID){//修改
                let data=this.tableData?.find(t=>t.SoldOut_ID==SoldOut_ID);
                if(data){
                    data.Limited_Num=row.Limited_Num;
                    data.EAT_PHASENAME=row.EAT_PHASENAME;
                    data.EAT_PHASEID=row.EAT_PHASEID;
                    data.SoldOut_Type=row.SoldOut_Type;
                }
            }else if(SoldOut_ID){
                this.tableData.push(Object.assign({},row,{SoldOut_ID:SoldOut_ID}));
            }
        },
        /**点击修改沽清 */
        handleClick(row){
            if(row){
                this.selectPosFood={
                    Eat_XFKINDNAME:row.Eat_XFKINDNAME,//类别名称
                    SoldOut_ID:row.SoldOut_ID,//沽清id
                    Eat_XFBMID:row.Eat_XFBMID,//菜品ID
                    Eat_XFNAME:row.Eat_XFNAME,//菜品名称
                    Limited_Num:row.Limited_Num,
                    EAT_PHASENAME:row.EAT_PHASENAME,
                    EAT_PHASEID:row.EAT_PHASEID
                };
                this.selloutEditIshow=true;
            }
        },
        /**取消沽清 */
        cancelDoSoldOut(list){
            if(list && list.length>0){
                let title="您确认删除当前列表中的沽清信息吗？";
                if(list.length==1){
                    title="您确认删除【"+list[0].Eat_XFNAME+"】沽清信息吗？";
                }
                this.$confirm(title, "提示", {
					confirmButtonText: "确定",
					cancelButtonText:"取消",
					callback: (name) => {
						if(name=='confirm'){
							this.doSoldOut(list)
						}
					},
				}); 
            }
        },
        doSoldOut(list){
            const loading = this.$loading({
                text: "请求操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let doSoldOutList=[];
            list.forEach((row)=>{
                doSoldOutList.push({
                    SoldOut_ID:row.SoldOut_ID,//沽清id
                    Eat_XFBMID:row.Eat_XFBMID,//菜品id
                    Eat_XFNAME:row.Eat_XFNAME,//菜品名称
                    SoldOut_Type:row.SoldOut_Type==0? 1:3,// 1 取消沽清  3取消限量销售
                })
            })
            console.log("取消沽清数据",{
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                doSoldOutList:doSoldOutList
            })
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.DoSoldOut",{
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                doSoldOutList:doSoldOutList
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("取消沽清成功");
                    doSoldOutList.forEach((row)=>{
                        this.removeData(row.SoldOut_ID);
                    })
                    
                }else{
                    this.$message.error("取消沽清失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('取消沽清失败：'+e.message);
                console.log('取消沽清失败：',e);
            })  
        },
        /**点击确认沽清 */
        selloutEdit(doSoldOutInfo){
            if(doSoldOutInfo){
                doSoldOutInfo.Limited_Num=parseFloat(doSoldOutInfo.Limited_Num||0);
                if(doSoldOutInfo.Limited_Num>0){
                    doSoldOutInfo.SoldOut_Type=2;//限售数量
                }else{
                    if(this.operationType==1){//当前营业日期
                        doSoldOutInfo.SoldOut_Type=0;//沽清
                    }else{//非当前营业日期
                        doSoldOutInfo.SoldOut_Type=3;//取消沽清
                    }
                }

                if(!doSoldOutInfo.SoldOut_ID && (doSoldOutInfo.SoldOut_Type==2 || doSoldOutInfo.SoldOut_Type==3)){// 限售数量 或 取消 沽清 
                    this.tableData?.find(t=>{//查找对应的 明细id
                        if(t.Eat_XFBMID==doSoldOutInfo.Eat_XFBMID && t.EAT_PHASEID==doSoldOutInfo.EAT_PHASEID){
                            doSoldOutInfo.SoldOut_ID=t.SoldOut_ID;
                            return true;
                        }
                        return false;
                    });
                }

                if(!doSoldOutInfo.SoldOut_ID && this.operationType==2 && doSoldOutInfo.Limited_Num==0){
                    this.$message.warning('非当前营业日期沽清数量必须大于0！');
                    return;
                }

                const loading = this.$loading({
                    text: "请求操作中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                
                let userInfo= this.$auth.getUserInfo();
                this.$httpAES.post("Bestech.CloudPos.DoSoldOut",{
                    User_ID:userInfo?.User_ID,
                    Operator_Name:userInfo?.Login_Name,
                    Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                    doSoldOutList:[doSoldOutInfo]
                }).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode==0){
                        this.$message.success("沽清成功");
                        this.selloutEditIshow=false;
                        if(doSoldOutInfo.SoldOut_Type==3){//取消沽清
                            this.removeData(doSoldOutInfo.SoldOut_ID);
                        }else{
                            let SoldOut_ID= d.ResponseBody?.doSoldOutList[0]?.SoldOut_ID;
                            this.setData(doSoldOutInfo,SoldOut_ID);
                        }
                    }else{
                        this.$message.error("沽清失败："+d.ResponseHeader.ResultDesc);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$message.error('沽清失败：'+e.message);
                    console.log('沽清失败：',e);
                })               
            }
        },
        /**批量限量销售 过去数据引用 */
        selectOldDate(doSoldOutList){
            if(doSoldOutList){
                const loading = this.$loading({
                    text: "请求操作中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                doSoldOutList.forEach((item)=>{
                    item.SoldOut_Type=2;//限售数量
                })
                let userInfo= this.$auth.getUserInfo();
                this.$httpAES.post("Bestech.CloudPos.DoSoldOut",{
                    User_ID:userInfo?.User_ID,
                    Operator_Name:userInfo?.Login_Name,
                    Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                    doSoldOutList:doSoldOutList
                }).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode==0){
                        this.$message.success("批量沽清成功");
                        this.selectOldDateIshow=false;
                        doSoldOutList.forEach((item,index)=>{
                            let SoldOut_ID= d.ResponseBody?.doSoldOutList[index]?.SoldOut_ID;
                            item.Sold_Num=0;
                            this.setData(item,SoldOut_ID);
                        })
                    }else{
                        this.$message.error("批量沽清失败："+d.ResponseHeader.ResultDesc);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$message.error('批量沽清失败：'+e.message);
                    console.log('批量沽清失败：',e);
                })               
            }
        },
        /**批量限量销售 指定限量日期*/
        batDoSoldOut(doSoldOutList,rptDate,isClose){
            if(doSoldOutList){
                const loading = this.$loading({
                    text: "请求操作中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let userInfo= this.$auth.getUserInfo();
                let date=new Date(userInfo.Rpt_Date).Format('yyyy-MM-dd');//当前营业日期
                doSoldOutList.forEach((item)=>{
                    if(item.Limited_Num>0){
                        item.SoldOut_Type=2;//限售数量
                    }else{
                        if(rptDate==date){//当前营业日期
                            item.SoldOut_Type=0;//沽清
                            item.SoldOut_ID=''
                        }else{//取消限量
                            item.SoldOut_Type=3;//取消限量
                        }
                    }
                })
                
                this.$httpAES.post("Bestech.CloudPos.DoSoldOut",{
                    User_ID:userInfo?.User_ID,
                    Operator_Name:userInfo?.Login_Name,
                    Rpt_Date:rptDate,
                    doSoldOutList:doSoldOutList
                }).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode==0){
                        this.$message.success("批量限量销售成功");
                        if(isClose){
                            this.selectMouldIsShow=false;
                        }else{
                            this.$refs.selectMould?.loadData();
                        }
                        
                        if((new Date(this.businessHours)).Format('yyyy-MM-dd')==rptDate){
                            doSoldOutList.forEach((item,index)=>{
                                if(item.SoldOut_Type==3){//取消沽清
                                    this.removeData(item.SoldOut_ID);
                                }else{//限量或沽清
                                    let SoldOut_ID= d.ResponseBody?.doSoldOutList[index]?.SoldOut_ID;
                                    item.Sold_Num=0;
                                    this.setData(item,SoldOut_ID);
                                }
                            })
                        }
                    }else{
                        this.$message.error("批量限量销售失败："+d.ResponseHeader.ResultDesc);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$message.error('批量限量销售失败：'+e.message);
                    console.log('批量限量销售失败：',e);
                })               
            }
        },
        /**点击键盘 回撤 或 确定 */
        onEnter(){
            console.log(this.foodsPages)
            if(this.foodsPages && this.foodsPages.length==1){
                this.onPlaceFoods(this.foodsPages[0]);
            }
        },
        //刷新菜品数据
        RefreshData(){
            const loading = this.$loading({
                text: "信息加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$cacheData.RefreshSoldInfo().then(()=>{
                loading.close();
            }).catch((e)=>{ 
                loading.close();
                this.$message.error("刷新失败:"+e.message);
            });
        },
        //沽清模板设置页面
        goMould(){
            this.$router.push("saveSellOutMould");
        },
        //设置不自动取消估清
        goCancelSellOut(){
            this.$router.push("notAutoCancelSellOut");
        }
    }
}
</script>

<style lang="scss">
@import './sellOutSetUp.scss'
</style>